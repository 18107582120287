.date-picker .date-picker-input {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border: 1px solid var(--border-main-color);
    height: 2.857rem;
    min-width: 14.286rem;
    margin: 0;
    pointer-events: auto;
    padding: 0 0.571rem;

    -webkit-transition: border 0.25s ease-in-out;
    -moz-transition: border 0.25s ease-in-out;
    -o-transition: border 0.25s ease-in-out;
    transition: border 0.25s ease-in-out;
  }
  
  
  .date-picker.disabled .date-picker-input input::placeholder {
    color: var(--placeholder-disabled-color);
  }
  
  .date-picker.disabled .date-picker-input path {
    fill: var(--placeholder-disabled-color);
  }
  
  .date-picker .date-picker-input input {
    width: 100%;
    height: 100%;
    border: none;
    padding: 0;
    cursor: pointer;
    /* padding-right: 0.625rem; */
    background-color: transparent;
  }
  
  .date-picker .date-picker-input svg {
    cursor: pointer;
  }
  
  .date-picker .date-picker-input input:disabled {
    background-color: transparent;
    pointer-events: none;
  }
  
  .date-picker.disabled .date-picker-input svg {
    cursor: unset;
  }
  
  .date-picker .date-picker-input input::placeholder {
    color: var(--placeholder-color);
    opacity: 1;
  }
  
  .date-picker .date-picker-input input::-ms-input-placeholder {
    color: var(--placeholder-color);
  }
  
  .date-picker .date-picker-input:hover,
  .date-picker .date-picker-input:focus {
    outline: none;
    border: 1px solid var(--primary-color);
  }
  
  .date-picker.disabled .date-picker-input:hover,
  .date-picker.disabled .date-picker-input {
    border-color: var(--grey-palette-02);
    background-color: var(--grey-palette-03);
  }
  
  .date-picker .date-picker-input input:focus {
    outline: none;
  }