.projects-page {
  display: flex;
  flex-direction: column;
  gap: 2.7143rem;
  padding: 0 3.5714rem 0 3.5714rem;
}

.projects-page .page-header {
  display: flex;
  justify-content: space-between;
  min-height: 4.393rem;
  padding-top: 2.021rem;
  align-items: center;
}

.projects-page .page-header .addproject-button {
  padding: 0.714rem;
  height: 2.857rem;
}

.projects-page .cards {
  display: flex;
  gap: 3.0714rem;
}

.projects-page .table {
  gap: 2.7143rem;
}

.projects-page .table .filter {
  gap: 1.1429rem;
  display: flex;
  align-items: end;
}

.projects-page .search-button {
  margin-bottom: 0.429rem;
  height: 39.98px;
}

.table-container table tbody .table-cell.table-row-cell .options-cell {
  display: flex;
  flex-direction: row;
}

.projects-page .nivo {
  display: flex;
  gap: 3.0714rem;
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-row-cell
  .options-cell svg:hover path {
  fill: var(--grey-palette-04);
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-row-cell
  .options-cell svg:hover {
  cursor: pointer;
}

.projects-page
  .cards
  .card-container.detailed-card
  .detailed-card-info
  .detailed-card-status {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projects-page
  .cards
  .card-container.detailed-card
  .detailed-card-info
  .detailed-card-status
  .errors {
  display: flex;
  align-items: center;
}

.projects-page
  .cards
  .card-container.detailed-card
  .detailed-card-info
  .detailed-card-status
  .errors
  .total-errors {
  padding-left: 0.5714rem;
  padding-right: 1.2857rem;
  font-size: 1.1429rem;
}

.projects-page
  .cards
  .card-container.detailed-card
  .detailed-card-info
  .detailed-card-status
  .success {
  display: flex;
  align-items: center;
}
.projects-page
  .cards
  .card-container.detailed-card
  .detailed-card-info
  .detailed-card-status
  .success
  .total-success {
  padding-left: 0.5714rem;
  font-size: 1.1429rem;
}

.projects-page
  .cards
  .card-container.detailed-card
  .detailed-card-info
  .detailed-card-status
  .errors
  .icon
  path {
  fill: #f5525f;
}

.projects-page
  .cards
  .card-container.detailed-card
  .detailed-card-info
  .detailed-card-status
  .success
  .icon
  path {
  fill: #2db273;
}

.projects-page
  .cards
  .card-container.detailed-card
  .detailed-card-footer
  .caption.detailed-card-footer-label {
  color: var(--grey-palette-02);
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-cell.table-row-cell
  .name-cell {
  display: flex;
  flex-direction: column;
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-cell.table-row-cell
  .name-cell
  .label-name {
  font-weight: var(--font-weight-regular);
  color: var(--grey-palette-02);
}

.projects-page
  .table-container
  table
  tbody
  .table-row:hover
  .table-cell.table-row-cell
  .name-cell
  .label-name {
  color: var(--grey-palette-04);
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-cell.table-row-cell
  .name-cell
  .label-id {
  font-size: 0.8571rem;
  font-weight: var(--font-weight-regular);
  color: var(--grey-palette-04-alpha05);
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-cell.table-row-cell
  .stats-cell {
  display: flex;
  flex-direction: row;
  gap: 0.7143rem;
  justify-content: center;
  align-items: center;
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-cell.table-row-cell
  .stats-cell
  .content-errors,
.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-cell.table-row-cell
  .stats-cell
  .content-success {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3571rem;
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-cell.table-row-cell
  .stats-cell
  .label-errors {
  background-color: #f5525f;
  border-radius: 50%;
  width: 0.7143rem;
  height: 0.7143rem;
}

.projects-page
  .table-container
  table
  tbody
  .table-row
  .table-cell.table-row-cell
  .stats-cell
  .label-success {
  background-color: #2db273;
  border-radius: 50%;
  width: 0.7143rem;
  height: 0.7143rem;
}
