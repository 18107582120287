.table-select-single {
  --select-single-padding: 0.571rem;

  display: flex;
  align-items: center;
  height: 2.857rem;
  min-width: min-content;

  position: relative;
  gap: 1rem;
  padding-right: 1.286rem;
}

.table-select-single .native-input {
  flex-grow: 1;
  padding: 0;
  padding-left: 0.286rem;
  caret-color: var(--text-main-color);
}

.table-select-single.has-icon .native-input {
  padding-left: 0.429rem;
}

.table-select-single .table-select-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.429rem;
  padding: 0 0.286rem;
  height: 1.7143rem;
}

.table-select-single .table-select-container:hover {
  cursor: pointer;
  background-color: var(--grey-palette-05);
}

.table-select-single .table-select-container path {
  fill: white;
}

.table-select-single .native-input:focus {
  outline: none;
}

.table-select-single .table-select-single-icon {
  display: flex;
  align-items: center;
}

.table-select-single .table-select-single-options-panel {
  position: absolute;
  top: calc(1.7143rem + 0.1429rem);
  left: 0;
  min-width: 100%;
  z-index: 90;

  display: flex;
  flex-direction: column;
  overflow: auto;

  background-color: white;
  color: black;
}

.table-select-single .table-select-single-options-panel .single-select-actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 0.435rem 0.857rem;
  padding-bottom: 0;
}

.table-select-single .table-select-single-options-panel .single-select-option {
  padding: 0.357rem 0.857rem;
}

.table-select-single
  .table-select-single-options-panel
  .single-select-option.hover,
.table-select-single
  .table-select-single-options-panel
  .single-select-option:hover {
  background-color: var(--grey-palette-04);
  cursor: pointer;
}

.table-select-single
  .table-select-single-options-panel
  .single-select-option.selected {
  color: var(--primary-color);
  font-weight: var(--font-weight-semibold);
  background-color: var(--grey-palette-04);
}
