.empty-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2.143rem;
    width: 100%;
    margin-top: 265px; /* martelo a retirar depois */
    
}

.empty-container .empty-icon{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5.857rem;
  height: 5.857rem;
  border-radius: 50%;
  background-color: var(--primary-color-transparency-02);
}

.empty-container .empty-icon #empty-circles-container{
    position: relative;
    height: 6.25rem;
    width: 6.25rem;
    display: contents;
}

.empty-container .empty-icon #empty-circles-container .empty-first-circle,
.empty-container .empty-icon #empty-circles-container .empty-second-circle {
    position:absolute;
    top:50%;
    left:50%;
    height: 10rem;
    width: 10rem;
    transform:translate(-50%,-50%);
    border-radius:100%;
    border:1px solid var(--primary-color-transparency-02);
}


.empty-container .empty-icon #empty-circles-container .empty-second-circle {
    height: 13rem;
    width: 13rem;
}



.empty-container .empty-icon svg{
    height: 2.857rem;
    width: 2.857rem;
}

.empty-container .empty-icon svg path{
    fill: #ffffff;
}

.empty-container .empty-first-label{
    margin-top: 0.857rem;
}

.empty-container .empty-second-label{
    color: var(--grey-palette-03);
}

