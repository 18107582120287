.add-component-popup {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  min-height: 10rem;
}

.add-component-popup .inputs {
  padding: 0;
}

.add-component-popup .separator {
  padding: 1.1429rem 0 0 0;
  border-top: 0.0625rem solid var(--grey-palette-02);
}

.add-component-popup .act-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.add-component-popup .act-btns .text-button.white {
  padding-right: 2rem;
}

.add-component-popup .act-btns .button.primary {
  width: 7.1429rem;
  height: 2.8571rem;
}
