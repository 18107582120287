@import "colors.css";
@import "typography.css";

* {
  box-sizing: border-box;
}

body {
  width: 100vw;
  height: 100vh;
}



#root {
  width: 100%;
  height: 100%;
}



 *::-webkit-scrollbar {
  width: 0.571rem;

}

*::-webkit-scrollbar-track {
  background: none;
  border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--grey-palette-03);
  border-radius: 1.25rem;
  -webkit-border-radius: 1.25rem;
}
 
 body {
  margin: 0;
} 

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
