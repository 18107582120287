* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}


.app-portal .main-content{
  display: flex;
 flex-grow:1;
 /* padding: 0 3.5714rem 0 3.5714rem; */
 width:100%;
 overflow: hidden;
}

.app-portal .main-content .content{
  max-width: 100%;
  display: flex;
  flex-grow:1;
}

.app-portal .main-content .sidebar-content{
  display: flex;
  /* background-color: rgb(175, 171, 171); */
  color: rgb(77, 31, 31);
}





