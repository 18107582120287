.side-bar .icon {
  width: 5.7857rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 5.7857rem;
  cursor: pointer;
}

.side-bar .icon a.is-pending svg:hover path {
  fill: var(--grey-palette-04);
}

.side-bar .icon a.selected svg path {
  fill: var(--grey-palette-04);
}
