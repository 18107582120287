/* .page-container.log-details-page h1 {
  color: var(--grey-palette-02);
}
.page-container.log-details-page h1:nth-of-type(2) {
  color: var(--text-main-color);
} */

.page-container.log-details-page .line-separator {
  border: 2px solid var(--separator-bg);
}

.page-container.log-details-page .log-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.page-container.log-details-page .flex-layout {
  align-items: center;
}

.page-container.log-details-page .flex-layout .log-level {
  display: flex;
  align-items: center;
  gap: 0.4286rem;
}

.page-container.log-details-page .flex-layout .log-level.primary-color {
  color: var(--primary-color);
}
.page-container.log-details-page .flex-layout .log-level.primary-color .circle {
  background-color: var(--primary-color);
}

.page-container.log-details-page .flex-layout .log-level.red-color {
  color: var(--error-color);
}
.page-container.log-details-page .flex-layout .log-level.red-color .circle {
  background-color: var(--error-color);
}

.page-container.log-details-page .flex-layout .log-level.grey-color {
  color: var(--grey-palette-03);
}
.page-container.log-details-page .flex-layout .log-level.grey-color .circle {
  background-color: var(--grey-palette-03);
}

.page-container.log-details-page .flex-layout .log-level .circle {
  border-radius: 50%;
  width: 0.5714rem;
  height: 0.5714rem;
}

.page-container.log-details-page .log-description {
  word-break: break-word;
  white-space:pre-wrap
}

.page-container.log-details-page .table-container tbody .table-row .table-cell {
  font-size: 0.8571rem;
  padding: 0.5rem 0.857rem;
}

.page-container.log-details-page .table-container tbody .table-row .table-cell .options-cell path:hover {
  fill: var(--grey-palette-04);
  cursor: pointer;
}