.edit-project-popup {
  display: flex;
  flex-direction: column;
  min-width: 30rem;
  min-height: 15rem;
}

.edit-project-popup .act-btns {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.edit-project-popup .act-btns .text-button.white {
  padding-right: 2rem;
}

.edit-project-popup .act-btns .button.primary {
  width: 7.1429rem;
  height: 2.8571rem;
}
