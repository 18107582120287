.logs-realtime-page{

}

.logs-realtime-page .dark-screen{
  background-color: black;
  padding: 1rem;
  border-radius: 10px;
}

.logs-realtime-page .dark-screen .log{
  padding-bottom: 0.5rem;
  color: var(--grey-palette-03);
}