.detail-correlated-log-popup {
  display: flex;
  flex-direction: column;
  gap: 2.1429rem;
  width: 100%;
  height: 100%;
  max-width: 80vw;
  min-width: 80vw;
  max-height: 80vh;
  min-height: 80vh;
}

.detail-correlated-log-popup .detail-correlated-header {
  display: flex;
  gap: 2.1429rem;
  align-items: flex-start;
}

.detail-correlated-log-popup .detail-correlated-title {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  flex-grow: 1;
}

.detail-correlated-log-popup .log-level {
  display: flex;
  align-items: center;
  gap: 0.4286rem;
  padding: 6px 0px;
}

.detail-correlated-log-popup .log-level.primary-color {
  color: var(--primary-color);
}

.detail-correlated-log-popup .log-level.primary-color .circle {
  background-color: var(--primary-color);
}

.detail-correlated-log-popup .log-level.red-color {
  color: var(--error-color);
}

.detail-correlated-log-popup .log-level.red-color .circle {
  background-color: var(--error-color);
}

.detail-correlated-log-popup .log-level.grey-color {
  color: var(--grey-palette-03);
}

.detail-correlated-log-popup .log-level.grey-color .circle {
  background-color: var(--grey-palette-03);
}

.detail-correlated-log-popup .log-level .circle {
  border-radius: 50%;
  width: 0.5714rem;
  height: 0.5714rem;
}

.detail-correlated-log-popup .move-log-options {
  padding: 7px 0px;
}

.detail-correlated-log-popup .move-log-options,
.detail-correlated-log-popup
  .move-log-options
  .move-log-button {
  display: flex;
  align-items: center;
  gap: 0.143rem;
  user-select: none;
  cursor: pointer;
}

.detail-correlated-log-popup
  .move-log-options
  .move-log-button:hover {
  background-color: var(--grey-palette-05);
  cursor: pointer;
}

.detail-correlated-log-popup
  .move-log-options
  .move-log-button.rotate {
  transform: rotate(180deg);
}

.detail-correlated-log-popup
  .move-log-options
  .move-log-button
  svg {
  height: 1.14rem;
  width: 1.14rem;
}

.detail-correlated-log-popup
  .move-log-options
  .move-log-button.disable {
  opacity: 0.3;
  pointer-events: none;
}

.detail-correlated-log-popup .detail-close-popup {
  display: flex;
  align-items: center;
  /* flex-grow: 1; */
  justify-content: flex-end;
}

.detail-correlated-log-popup .detail-close-popup svg path {
  fill: var(--grey-palette-04);
}

.detail-correlated-log-popup
  .detail-close-popup
  svg
  path:last-child {
  fill: none;
}

.detail-correlated-log-popup .detail-correlated-info {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  height: 50vh;
}

.detail-correlated-log-popup
  .detail-correlated-info
  .detail-correlated-info-main {
  display: flex;
  --gap: 10rem;
  gap: var(--gap);

}

.detail-correlated-log-popup
  .detail-correlated-info
  .detail-correlated-info-main
  .detail-correlated-info-main-column {
  display: flex;
  flex-direction: column;
  flex: 1 0 calc(50% - var(--gap) / 2);
  font-size: 0.857rem;

}


.detail-correlated-log-popup
  .detail-correlated-info
  .detail-correlated-info-description
  .info-block {

  margin-bottom: 0;
  word-break: break-word;
  white-space:pre-wrap
}
