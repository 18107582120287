@import "colors.css";

@import url('./fonts/poppins/poppins.css');

:root {

    --font-family: "Poppins";

    --font-weight-thin: 100;
    --font-weight-light: 300;
    --font-weight-regular: 400;

    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;
}


h1, h2, h3, h4, .subtitle, body, caption, .caption, input {
    color: var(--text-main-color);
    font-family: var(--font-family), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

h1 {
    font-size: 1.714rem;
    font-weight: var(--font-weight-semibold);
}

h2 {
    font-size: 1.429rem;
    font-weight: var(--font-weight-semibold);
}

h3 {
    font-size: 1.286rem;
    font-weight: var(--font-weight-semibold);
}

h4, .subtitle {
    font-size: 1.142rem;
    font-weight: var(--font-weight-medium);
}


html, body{
    font-size: 14px;
    font-weight: var(--font-weight-regular);
}

input {
    font-size: 1rem;
    font-weight: var(--font-weight-regular);
    caret-color: var(--text-main-color);
}

caption, .caption {
    font-size: 0.857rem;
    font-weight: var(--font-weight-regular);
}

