.select-single {
  --select-single-padding: 0.571rem;
  display: flex;
  align-items: center;
  height: 2.857rem;
  /*     min-width: 14.286rem;  */
  border: 1px solid var(--border-main-color);
  padding: 0 var(--select-single-padding);
  pointer-events: auto;
  position: relative;
}

.select-single .arrow-down-icon {
  cursor: pointer;
  min-width: 1.2857rem;
  height: 1.2857rem;
}
.select-single .arrow-down-icon path {
  fill: #ffffff;
}

.select-single:hover,
.select-single:focus-within {
  border-color: var(--primary-color);
}

.select-single.error,
.select-single.error:hover {
  border-color: var(--error-color);
}

.select-single.disabled,
.select-single.disabled:hover {
  border-color: var(--bg-disabled-color);
  background-color: var(--bg-disabled-color);
}

.select-single .native-input {
  flex-grow: 1;
  padding: 0;
  padding-left: 0.286rem;
  min-width: 2.8571rem;
  caret-color: var(--text-main-color);
  border: none;
  background-image: none;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.select-single.has-icon .native-input {
  padding-left: 0.429rem;
}

.select-single .native-input:focus {
  outline: none;
}

.select-single .native-input::placeholder {
  color: var(--placeholder-color);
}

.select-single.disabled .native-input {
  color: var(--text-disabled-color);
}

.select-single.disabled .native-input::placeholder {
  color: var(--placeholder-disabled-color);
}

.select-single .select-single-icon {
  display: flex;
  align-items: center;
}

.select-single:hover .select-single-icon svg,
.select-single:focus-within .select-single-icon svg {
  fill: var(--primary-color);
}

.select-single.error .select-single-icon svg,
.select-single.error:hover .select-single-icon svg,
.select-single.error:focus-within .select-single-icon svg {
  fill: var(--error-color);
}

.select-single .select-single-options-panel {
  position: absolute;
  top: calc(2.857rem + 0.45rem);
  left: 0;
  min-width: 100%;
  z-index: 90;
  display: flex;
  flex-direction: column;
  overflow: auto;
  background-color: white;
  color: black;
  max-height: 11.6429rem;  
}

.select-single .select-single-options-panel .single-select-actions {
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding: 0.435rem 0.857rem;
  padding-bottom: 0;
}

.select-single .select-single-options-panel .single-select-option {
  padding: 0.357rem 0.857rem;
}

.select-single .select-single-options-panel .single-select-option.hover,
.select-single .select-single-options-panel .single-select-option:hover {
  background-color: var(--grey-palette-04);
  cursor: pointer;
}

.select-single .select-single-options-panel .single-select-option.selected {
  display: flex;
  justify-content: space-between;
  color: var(--primary-color);
  font-weight: var(--font-weight-semibold);
  background-color: var(--grey-palette-04);
}
.select-single
  .select-single-options-panel
  .single-select-option.selected
  .check-option
  path {
  fill: var(--primary-color);
}
.select-single
  .select-single-options-panel
  .single-select-option.selected
  .check-option
  path:first-child {
  fill: none;
}

/* .select-single .select-single-options-panel .single-select-option.selected .selected-icon{
    padding: 0.357rem 0.857rem; 
} */

.mini-text-button {
  padding: 0.5rem;
  font-size: 0.857rem;
  font-weight: var(--font-weight-semibold);
}
