.date-picker-panel-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref {
    position: relative;
    width: 100vw;
    height: 100vh;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel {
    position: absolute;
    width: max-content;
    min-width: 12.5rem;
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 0.375rem 0.5rem;
    z-index: 2;
    background-color: var(--bg-main-color);
    border: 1px solid var(--border-main-color);
    box-shadow: 0px 0.375rem 0.9375rem 0.3125rem rgba(91, 206, 221, 0.1);
    font-size: 0.875rem;
    opacity: 0;
    pointer-events: auto;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.25rem;
    margin-bottom: 0.125rem;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-date {
    font-weight: 700;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon {
    width: 1.625rem;
    height: 1.625rem;
    border-radius: 0.375rem;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon:hover {
    background-color: var(--white-gray);
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon svg {
    width: auto;
    height: 1.5rem;
  }

  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon path {
    fill: #ffffff;
}
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon-left {
    transform: rotate(90deg);
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon-left.disable {
    cursor: unset;
    pointer-events: none;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon-left.disable path {
    opacity: .3;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon-right {
    transform: rotate(-90deg);
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon-right.disable {
    cursor: unset;
    pointer-events: none;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-selector .date-picker-selector-icon-right.disable path {
    opacity: .3;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-calendar-header {
    display: flex;
    font-weight: 700;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-calendar-cell {
    padding: 0.25rem;
    width: 2.375rem;
    height: 2.375rem;
    margin: 0 0.125rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-calendar-row {
    display: flex;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .date-picker-calendar-day-cell {
    cursor: pointer;
    transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  }
  
  .date-picker-calendar-day-cell:not(.date-picker-calendar-day-cell-selected):hover {
    background-color: var(--primary-color);
  }
  
  .date-picker-calendar-day-cell:active {
    background-color: #d1d1d1;
  }
  
  .date-picker-calendar-day-cell-today {
    background-color: var(--status-grey-transparency-3);
    color: var(--secondary-color);
  }
  
  .date-picker-calendar-day-cell-selected {
    background-color: var(--primary-color);
    color: var(--text-main-color);
  }
  
  
  .out-of-selected-month {
    opacity: 0.5;
    pointer-events: none;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .options-clear {
    padding: 0.25rem 0;
    margin-bottom: 0.25rem;
    text-align: center;
    background-color: var(--white-gray);
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    color: var(--light-gray);
    cursor: pointer;
  }
  
  .date-picker-panel-wrapper .date-picker-panel-wrapper-ref .date-picker-select-panel .options-clear:hover {
    opacity: 0.6;
  }