.components-page .components-page-header {
    justify-content: space-between;
}

.components-page .table-container table tbody .table-row .table-cell.table-row-cell .name-cell .label-id {
    font-size: .8571rem;
    font-weight: var(--font-weight-regular);
    color: var(--grey-palette-04-alpha05);
}

.components-page .table-container table tbody .table-row .table-cell.table-row-cell .stats-cell {
    display: flex;
    flex-direction: row;
    gap: .7143rem;
    justify-content: center;
    align-items: center;
}

.components-page .table-container table tbody .table-row .table-cell.table-row-cell .stats-cell .content-errors,
.components-page .table-container table tbody .table-row .table-cell.table-row-cell .stats-cell .content-success {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .3571rem;
}

.components-page .table-container table tbody .table-row .table-cell.table-row-cell .stats-cell .label-errors {
    background-color: #f5525f;
    border-radius: 50%;
    width: .7143rem;
    height: .7143rem;
}

.components-page .table-container table tbody .table-row .table-cell.table-row-cell .stats-cell .label-success {
    background-color: #2db273;
    border-radius: 50%;
    width: .7143rem;
    height: .7143rem;
}

.components-page .table-container table tbody .table-row .table-cell.table-row-cell .stats-cell .label-none {
    background-color: var(--grey-palette-02);
    border-radius: 50%;
    width: .7143rem;
    height: .7143rem;
}