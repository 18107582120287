 .table-footer-pagination{
    height: 3rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0 0.857rem;
    font-size: 0.857rem;
    font-weight: var(--font-weight-regular);
    color: var(--grey-palette-02);
    gap: 0.714rem;
    user-select: none;

}

 .table-footer-pagination 
.table-footer-pagination-buttons{
display: flex;
gap: 0.143rem;
}

 .table-footer-pagination .table-footer-pagination-button{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.71rem;
    width: 1.71rem;

}

 .table-footer-pagination .table-footer-pagination-button:hover{
    background-color: var(--grey-palette-05);
    cursor: pointer;
    
}

 .table-footer-pagination .table-footer-pagination-button svg{
    height: 1.14rem;
    width: 1.14rem;
}

.table-footer-pagination .table-footer-pagination-button.rotate{
    transform: rotate(180deg);
}

 .table-footer-pagination .table-footer-pagination-button.disable{
    opacity: .3;
    pointer-events: none;
    
}


