.table-row{
    border-bottom: 1px solid var(--border-table-cell)
    
}

.table-row:hover{
    background-color: var(--grey-palette-05);
}

.table-row:last-child{
    border: none;
}

.table-row:hover .table-row-cell{
    color: var(--text-main-color);
}

.table-row.clickable:hover{
    cursor: pointer;
}

