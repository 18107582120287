.page-container.logs-page
  .flex-layout.horizontal.horizontal-start.vertical-end.filters {
  gap: 1.1429rem;
}

.page-container.logs-page .filters {
  gap: 1.1429rem;
}

.page-container.logs-page .filters .button-container{
  height: 100%;
  flex-grow: 1;
  padding-bottom: 0.429rem;
  display: flex;
  align-items: end;
  flex-direction: row;
}

.page-container.logs-page .filter-grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: start;
  gap: 1.1429rem;
}

.page-container.logs-page .button-container{
  padding-bottom: 0.429rem;
  gap: 1.1429rem;
  display: flex;
  flex-direction: row;
}



.page-container.logs-page .filters .button-container .search-button{
  height: 2.8571rem;
  min-width: 6.4286rem;
  flex-grow: 1;
}


.page-container.logs-page .table-container tbody .table-row .table-cell {
  font-size: 0.8571rem;
  overflow-wrap: anywhere;
}

.page-container.logs-page
  table
  tbody
  .table-row
  .table-row-cell
  .options-cell
  path:hover {
  fill: var(--grey-palette-04);
  cursor: pointer;
}

.page-container.logs-page .title-cell {
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
