.table-container {
  display: flex;
  width: 100%;
  position: relative;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px;
}

.table-container table td {
  padding: 0;
}

.table-container table thead {
  height: 2.571rem;
}

.table-container table .table-column-header {
  display: flex;
  align-items: center;
  gap: 0.29rem;
  padding: 0 0.857rem;
  font-size: 1rem;
}

.table-container table .table-column-header.header-clickable {
  cursor: pointer;
  user-select: none;
}

.table-container table .table-column-header .orderby-icon {
  display: none;
  align-items: center;

}

.table-container .table-column-header.header-clickable:hover .orderby-neutral {
  display: flex;
  opacity: 0.3;
}

.table-container .table-column-header.header-clickable .orderby-icon.show-arrow {
  display: flex;
}

.table-container .table-loader-container {
  position:absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30rem;
}

.table-column-header {
  font-weight: var(--font-weight-semibold);
}
