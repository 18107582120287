.app-portal {
    width: 100vw;
    height: 100vh;
    overflow:hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

.gap {
    gap: 1.143rem
}

