.spacer {

}

.spacer.horizontal {
    min-width: 1.143rem;
}

.spacer.vertical {
    min-height: 1.143rem;
}
